import {IFeaturedProduct} from "./featuredProducts";

export const cameras: IFeaturedProduct[] = [
    {
        name: "ARRI Alexa 35",
        category: "Digital Cinema",
        slug: "arri-alexa-35",
        cloudinaryImageId: "REIS/products/62f08105a3ef1f63117849b9/arri_alexa_35_camera_03",
        width: 2369,
        height: 2750
    },
    {
        name: "ARRI Alexa Mini LF",
        category: "Digital Cinema",
        slug: "arri-alexa-mini-lf",
        cloudinaryImageId: "REIS/products/5fbb09092a8ef00906445ec2/arri_alexa_mini_lf_profile",
        width: 2500,
        height: 1884
    },
    {
        name: "ARRI Alexa Mini",
        category: "Digital Cinema",
        slug: "arri-alexa-mini",
        cloudinaryImageId: "REIS/products/5fb7454a35394a6721e5a6ed/arri_alexa_mini_camera",
        width: 2300,
        height: 2044
    },
    {
        name: "ARRI Amira Premium",
        category: "Digital Cinema",
        slug: "arri-amira-premium",
        cloudinaryImageId: "REIS/products/5fbafa6d5e6b732c8ad7739f/arri_amira_cooke_mini_s4_camera_package_2",
        width: 950,
        height: 950
    },
    {
        name: "Canon C300 MKIII",
        category: "Digital Cinema",
        slug: "canon-c300-mkiii-base-kit",
        cloudinaryImageId: "REIS/products/61a84e6d752e42ba913738eb/C300_3_3_4",
        width: 2160,
        height: 2750
    },
    {
        name: "Canon C70",
        category: "Digital Cinema",
        slug: "canon-eos-c70-camera-kit",
        cloudinaryImageId: "REIS/products/61bbef870cd67f3a7262d1f9/1M2A2195_6",
        width: 2500,
        height: 2446
    },
    {
        name: "Sony FX9",
        category: "Digital Cinema",
        slug: "sony-pxw-fx9-camera",
        cloudinaryImageId: "REIS/products/5f572abbf434b200420dd8f7/sony_pxw_fx9_large_format_camera_3",
        width: 970,
        height: 867
    },
    {
        name: "Canon C500 MKII",
        category: "Digital Cinema",
        slug: "canon-c500-mkii-camera",
        cloudinaryImageId: "REIS/products/5fb774b6912b4511f935c837/canon_c500_mkii_camera",
        width: 900,
        height: 1100
    },
    {
        name: "Canon C300 MKII",
        category: "Digital Cinema",
        slug: "canon-c300-mark-ii",
        cloudinaryImageId: "REIS/products/5fb70d1b35394a5a5ee58876/canon_c300_mark_ii_arri_accessories_no_handgrips_2",
        width: 1000,
        height: 1000
    },
    {
        name: "Canon C200",
        category: "Digital Cinema",
        slug: "canon-c200",
        cloudinaryImageId: "REIS/products/5fc5bc89b4a62f11a073e19f/canon_c200_tilta_accessories_2",
        width: 999,
        height: 944
    },
    {
        name: "Canon EOS R5",
        category: "Mirrorless",
        slug: "canon-eos-r5",
        cloudinaryImageId: "REIS/products/6097b606055460437a376786/canon_eos_r5_mirrorless_camera",
        width: 2500,
        height: 2016
    },
    {
        name: "Sony A7s III",
        category: "Mirrorless",
        slug: "sony-a7s-iii",
        cloudinaryImageId: "REIS/products/5fbb0f572a8ef058114462b6/1M2A2229_3",
        width: 2200,
        height: 1965
    },
    {
        name: "Sony A7 IV",
        category: "Mirrorless",
        slug: "sony-a7-iv-mirrorless-camera",
        cloudinaryImageId: "REIS/products/61bfd1edcbd7eee3d9045c88/1M2A2180",
        width: 2500,
        height: 1851
    },
    {
        name: "Canon XF405",
        category: "Camcorders",
        slug: "canon-xf405",
        cloudinaryImageId: "REIS/products/5fcd88720b6fa69c3a3fa087/canon_xf405_4K_camcorder",
        width: 1000,
        height: 996
    },
];

export const lenses: IFeaturedProduct[] = [
    {
        name: "ARRI 16-32mm Signature",
        category: "Cinema Lenses",
        slug: "arri-signature-zoom-16-32mm",
        cloudinaryImageId: "REIS/products/650403332465f4236022a25d/arri_16_32_signature_zoom_offshoot",
        width: 2300,
        height: 1770
    },
    {
        name: "ARRI 24-75mm Signature",
        category: "Cinema Lenses",
        slug: "arri-signature-zoom-24-75mm",
        cloudinaryImageId: "REIS/products/6504035a2465f4236022a2f1/arri_24_75mm_signature_zoom_lens_offshoot",
        width: 2500,
        height: 1898
    },
    {
        name: "ARRI 65-300mm Signature",
        category: "Cinema Lenses",
        slug: "arri-signature-zoom-65-300mm",
        cloudinaryImageId: "REIS/products/65718be8b9f461c7f0aa5cfe/arri_65_300mm_t2_8_signature_zoom",
        width: 2750,
        height: 2057
    },
    {
        name: "Atlas Mercury Anamorphic",
        category: "Cinema Lenses",
        slug: "atlas-mercury-set-3a",
        cloudinaryImageId: "REIS/products/66656d3b2109f56e9d9a6db8/atlas_mercury_1_5x_anamorphic_group_I",
        width: 2750,
        height: 2144
    },
    {
        name: "TLS Petzval",
        category: "Cinema Lenses",
        slug: "tls-petzval-set",
        cloudinaryImageId: "REIS/products/65e7b95de104e905cda8fcbb/tls_petzvals",
        width: 2800,
        height: 1928
    },
    {
        name: "TLS Canon FD",
        category: "Cinema Lenses",
        slug: "tls-canon-fd-lens-set",
        cloudinaryImageId: "REIS/products/639b9ca2ec90db0ad1d820dc/TLS_Canon_FD_prime_lens_set_2.png",
        width: 2900,
        height: 2185
    },
    {
        name: "TLS Mamiya 645",
        category: "Cinema Lenses",
        slug: "tls-mamiya-645-lenses",
        cloudinaryImageId: "REIS/products/633bb6c7a5ce265b853b9e22/tls_mamiya_645_lpl_lenses_2",
        width: 2750,
        height: 2146
    },
    {
        name: "TLS Cooke Speed Panchro",
        category: "Cinema Lenses",
        slug: "tls-rehoused-cooke-speed-panchro-lenses",
        cloudinaryImageId: "REIS/products/5fb7709c912b455bb235c40c/tls_cooke_speed_panchro_6_set_hr_3",
        width: 2500,
        height: 2136
    },
    {
        name: "Sigma FF High Speed",
        category: "Cinema Lenses",
        slug: "sigma-ff-high-speed-prime-set-10",
        cloudinaryImageId: "REIS/products/621da88d6ec7391c14d72432/Sigma_10_5_7",
        width: 2750,
        height: 1744
    },
    {
        name: "Atlas Orion 2x Anamorphic",
        category: "Cinema Lenses",
        slug: "atlas-orion-2x-anamorphic-full-set",
        cloudinaryImageId: "REIS/products/5fc5b9c0b4a62f95bd73dd95/atlas_oriion_anamorphic_six_lens_set_3",
        width: 2500,
        height: 1760
    },
    {
        name: "Leica R Cinemod",
        category: "Cinema Lenses",
        slug: "leica-r-cinemod-set-6",
        cloudinaryImageId: "REIS/products/61919a284a20525e9b766905/leica_r_cinemod_lens_set_3",
        width: 2700,
        height: 2361
    },
    {
        name: "Leica / Leitz Summicron-C",
        category: "Cinema Lenses",
        slug: "leica-leitz-summicron-c",
        cloudinaryImageId: "REIS/products/5fbb42a85e6b738d05d7bec3/leica_leitz_summicron_c_six_lens_set_hr_3",
        width: 2600,
        height: 2297
    },
    {
        name: "Cooke Mini S4/i",
        category: "Cinema Lenses",
        slug: "cooke-mini-s4i-six-lens-set",
        cloudinaryImageId: "REIS/products/5fb741a435394a631fe5a51f/cooke_mini_s4_i_lens_set_hr",
        width: 2600,
        height: 2615
    },
    {
        name: "Angenieux 25-250mm",
        category: "Cinema Lenses",
        slug: "angenieux-optimo-style-25-to-250mm",
        cloudinaryImageId: "REIS/products/5fbb2a2b2a8ef03c5a447d47/angenieux_optimo_style_25_250_hr_4",
        width: 2536,
        height: 3537
    },
    {
        name: "Canon RF 24-70 f/2.8L",
        category: "Canon RF Lenses",
        slug: "canon-rf-24-70mm-f28l-is-usm",
        cloudinaryImageId: "REIS/products/61d2d860cbd7ee0d250961fe/1M2A2349_3",
        width: 1803,
        height: 2900
    },
    {
        name: "Canon RF 50 f/1.2L",
        category: "Canon RF Lenses",
        slug: "canon-rf-50mm-f12l-usm",
        cloudinaryImageId: "REIS/products/61cecd029b3c4a7060180711/1M2A2095_2",
        width: 1709,
        height: 2600
    },
    {
        name: "Zeiss Compact Prime CP.3",
        category: "Cinema Lenses",
        slug: "zeiss-compact-prime-cp3-lenses",
        cloudinaryImageId: "REIS/products/5fb9efdba9eb36fdba7b4267/zeiss_cp3_7_lens_set_hr_2",
        width: 2500,
        height: 2077
    },
    {
        name: "Canon RF 15-35mm f/2.8L",
        category: "Canon RF Lenses",
        slug: "canon-rf-15-35mm-f28l-is-usm-lens",
        cloudinaryImageId: "REIS/products/6088cb55b5cfff41fb1a952e/0U1A0074_2",
        width: 1841,
        height: 3000
    },
    {
        name: "Canon RF 28-70mm f/2L",
        category: "Canon RF Lenses",
        slug: "canon-rf-28-70mm-f2l-usm-lens",
        cloudinaryImageId: "REIS/products/608626cbe87af8def257460d/canon_rf_28_70_f2_hr_7",
        width: 1904,
        height: 2750
    },
    {
        name: "Canon RF 70-200mm f/2.8L",
        category: "Canon RF Lenses",
        slug: "canon-rf-70-200mm-f28l-is-usm-lens",
        cloudinaryImageId: "REIS/products/6088b42cb5cfff37661a9378/canon_rf_70_200_f2_8_hr_6",
        width: 2200,
        height: 3165
    },
    {
        name: "Sony FE 50mm f/1.2 GM",
        category: "Sony E Lenses",
        slug: "sony-fe-50mm-f12-gm",
        cloudinaryImageId: "REIS/products/61de177b1bc66254daeff6d4/1M2A2277_3",
        width: 1858,
        height: 2500
    },
    {
        name: "Sony FE 85mm f/1.4 GM",
        category: "Sony E Lenses",
        slug: "sony-fe-85mm-f14-gm",
        cloudinaryImageId: "REIS/products/61de17879b3c4a0f4c1af120/1M2A2305_2",
        width: 1874,
        height: 2600
    },
    {
        name: "Sony FE 16-35mm F2.8 GM",
        category: "Sony E Lenses",
        slug: "sony-fe-16-35mm-f28-gm-lens",
        cloudinaryImageId: "REIS/products/5fbb123e2a8ef01a154463e9/137A8488_2",
        width: 1927,
        height: 3000
    },
    {
        name: "Sony 24-70mm f/2.8 GM",
        category: "Sony E Lenses",
        slug: "sony-fe-24-70mm-f2-8-gm-lens",
        cloudinaryImageId: "REIS/products/5fc489a8b4a62f210472e173/sony_fe_24_70mm_f2_8_GM_lens_hr_4",
        width: 1950,
        height: 2833
    },
    {
        name: "Sony 70-200mm f/2.8 GM",
        category: "Sony E Lenses",
        slug: "sony-fe-70-200mm-f2-8-gm-lens",
        cloudinaryImageId: "REIS/products/5fbf44e8752980152aecdb84/sony_fe_70_200mm_f2_8_GM_lens_hr_3",
        width: 2100,
        height: 3584
    },
];

export const lights: IFeaturedProduct[] = [
    {
        name: "Nanlux Evoke 900C Fresnel",
        category: "Continuous Lighting",
        slug: "nanlux-evoke-900c-fl-35yk-fresnel-kit",
        cloudinaryImageId: "REIS/products/65bdfd533ada0c0e1473c789/nanlux_evoke_900c_with_fl_35_yk_fresnel_2",
        width: 2300,
        height: 2095
    },
    {
        name: "Nanlux Evoke 900C",
        category: "Continuous Lighting",
        slug: "nanlux-evoke-900c",
        cloudinaryImageId: "REIS/products/65bdf6da3ada0c0e14735f2c/nanlux_evoke_900c",
        width: 3000,
        height: 2401
    },
    {
        name: "Nanlite PavoSlim 120C",
        category: "Continuous Lighting",
        slug: "nanlite-pavoslim-120c",
        cloudinaryImageId: "REIS/products/667232e61dc35358b7270b2f/nanlite_pavoslim_120C_rgbww_light_panel",
        width: 2750,
        height: 1863
    },
    {
        name: "Nanlux Evoke 1200B Fresnel",
        category: "Continuous Lighting",
        slug: "nanlux-evoke-1200b-fl-35yk-fresnel-kit",
        cloudinaryImageId: "REIS/products/65bee4d9322f3aab7025af2f/nanlux_evoke_1200B_with_fl_35yk_fresnel",
        width: 2250,
        height: 1982
    },
    {
        name: "Nanlux Evoke 1200B",
        category: "Continuous Lighting",
        slug: "nanlux-evoke-1200b-kit",
        cloudinaryImageId: "REIS/products/63fbdc25239f89019fe6f66d/nanlux_evoke_1200b_led",
        width: 3000,
        height: 2878
    },
    {
        name: "Aputure LS 1200d",
        category: "Continuous Lighting",
        slug: "aputure-lightstorm-1200d-pro-kit",
        cloudinaryImageId: "REIS/products/62c795ef727bcbb351328c47/aputure_ls_1200d_pro",
        width: 2750,
        height: 2083
    },
    {
        name: "Aputure LS 600c",
        category: "Continuous Lighting",
        slug: "aputure-lightstorm-600c-pro-kit",
        cloudinaryImageId: "REIS/products/62c7908e7c23d2abb6056bd3/aputure_ls_600c_led_light_fixture_2",
        width: 3000,
        height: 2443
    },
    {
        name: "Nanlite PavoTube II 15X",
        category: "Continuous Lighting",
        slug: "nanlite-pavotube-ii-15x-2ft-rgbww-led-tube-kit-4",
        cloudinaryImageId: "REIS/products/61f9f694c197f935a78de662/1M2A5662_4",
        width: 4000,
        height: 3610
    },
    {
        name: "ARRI Skypanel S30-C",
        category: "Continuous Lighting",
        slug: "arri-skypanel-s30-c",
        cloudinaryImageId: "REIS/products/5fbb47762a8ef024054494aa/arri_skypanel_s30-c_led_light",
        width: 949,
        height: 949
    },
    {
        name: "ARRI Skypanel S60-C",
        category: "Continuous Lighting",
        slug: "arri-skypanel-s60-c",
        cloudinaryImageId: "REIS/products/5fb62bc735394a5b6ae57062/Arri__Skypanel_s60c_led_light_fixture",
        width: 1100,
        height: 721
    },
    {
        name: "Aputure LS 600d Pro",
        category: "Continuous Lighting",
        slug: "aputure-lightstorm-600d-pro-kit",
        cloudinaryImageId: "REIS/products/60075e483f65ef3a2b5bb760/aputure_lightstorm_600d_pro_2",
        width: 2800,
        height: 2282
    },
    {
        name: "Profoto B1X",
        category: "Strobe Lighting",
        slug: "profoto-b1x-location-kit-500-airttl",
        cloudinaryImageId: "REIS/products/5fc8665bf3602e0be653981f/profoto_b1x_portable_strobe_monobloc",
        width: 1000,
        height: 774
    },
    {
        name: "Aputure LS 300d II",
        category: "Continuous Lighting",
        slug: "aputure-lightstorm-300d-ii",
        cloudinaryImageId: "REIS/products/5f8521f048aed4e3c4ca250b/aputure_lightstorm_300d_ii",
        width: 1100,
        height: 1011
    },
    {
        name: "Aputure LS 300x",
        category: "Continuous Lighting",
        slug: "aputure-light-storm-300x",
        cloudinaryImageId: "REIS/products/5fb5ecf935394af047e55065/aputure_lightstorm_300x",
        width: 1000,
        height: 1098
    },
    {
        name: "ARRI Skypanel S120-C",
        category: "Continuous Lighting",
        slug: "arri-skypanel-s120-c",
        cloudinaryImageId: "REIS/products/608f62bba8cd2202966a51db/Arri_Skypanel_S120C_led_light_fixture_2",
        width: 1400,
        height: 774
    },
    {
        name: "Aputure LS 60d",
        category: "Continuous Lighting",
        slug: "aputure-lightstorm-60d",
        cloudinaryImageId: "REIS/products/60beabd53714e4bd38877e11/aputure_60d_fixture",
        width: 2665,
        height: 3000
    },
    {
        name: "K5600 Joker 800 HMI",
        category: "Continuous Lighting",
        slug: "k5600-joker-800-hmi",
        cloudinaryImageId: "REIS/products/5ffbc064c11f6e41fc47a496/k5600_joker_800W_HMI_light_3",
        width: 1000,
        height: 1000
    },
    {
        name: "Aputure F10 Fresnel",
        category: "Light Modifiers",
        slug: "aputure-f10-fresnel",
        cloudinaryImageId: "REIS/products/60109d9585394c2f736bfbed/aputure_f10_fresnel",
        width: 2026,
        height: 2200
    },
    {
        name: "Aputure Spotlight Mount",
        category: "Light Modifiers",
        slug: "aputure-spotlight-mount-kit",
        cloudinaryImageId: "REIS/products/5fc87399573f6a3e961c6d2b/aputure_spotlight_mount",
        width: 3300,
        height: 3082
    },
];

export const accessories: IFeaturedProduct[] = [
    {
        name: "cmotion Cinefade",
        category: "Filters / ECS",
        slug: "cmotion-cinefade",
        cloudinaryImageId: "REIS/products/66f66a33d7bf7a57560e1b70/cmotion_cinefade_motorised_variable_nd_03",
        width: 953,
        height: 1189
    },
    {
        name: "ARRI Impression V Filters",
        category: "Filters",
        slug: "arri-impression-v-filter-kit",
        cloudinaryImageId: "REIS/products/6555a9c5a58aae3959f6a11c/arri_impression_v_filter_set",
        width: 1605,
        height: 2200
    },
    {
        name: "ARRI Master Grips",
        category: "FIZ Control",
        slug: "arri-master-grips",
        cloudinaryImageId: "REIS/products/630326df4ff3755d846b746d/arri_master_grips",
        width: 4000,
        height: 3360
    },
    {
        name: "ARRI Hi-5",
        category: "FIZ Control",
        slug: "arri-hi-5-kit",
        cloudinaryImageId: "REIS/products/6302c65bae76c1596cb47949/arri_hi5",
        width: 2500,
        height: 2146
    },
    {
        name: "ARRI cforce mini RF",
        category: "FIZ Control",
        slug: "arri-cforce-mini-rf-lens-motor",
        cloudinaryImageId: "REIS/products/6302d3ad5835e049fcf163c8/arri_cforce_mini_rf_lens_motor",
        width: 2066,
        height: 3000
    },
    {
        name: "Easyrig Vario 5 + Stabil",
        category: "Easyrigs",
        slug: "easyrig-vario-5-with-stabil-g2-stabiliser",
        cloudinaryImageId: "REIS/products/6237bca8913e8d15ff26bee8/1M2A6171_10",
        width: 2122,
        height: 4000
    },
    {
        name: "Tentacle Sync Track E",
        category: "Audio Recorders",
        slug: "tentacle-sync-track-e-recorder-twin-kit",
        cloudinaryImageId: "REIS/products/622548aee7b8c36ad461a30f/1M2A6036_3",
        width: 2100,
        height: 2136
    },
    {
        name: "DJI Ronin RS 2",
        category: "Stabilisers",
        slug: "dji-ronin-rs-2-kit",
        cloudinaryImageId: "REIS/products/6100bcb256a0fc4a49293056/dji_ronin_rs2_kit",
        width: 3000,
        height: 4564
    },
    {
        name: "Inovativ Voyager 42 NXT",
        category: "Carts",
        slug: "inovativ-voyager-42-nxt-cart-kit",
        cloudinaryImageId: "REIS/products/6181fcc1112481b1180a3575/inovativ_voyager_42_nxt_x_cart",
        width: 3500,
        height: 3076
    },
    {
        name: "ARRI LMB 4x5 Matte Box",
        category: "Matte Boxes",
        slug: "arri-lmb-4x5-matte-box-pro-kit",
        cloudinaryImageId: "REIS/products/616f70f92e34775217780d58/1M2A4833_3",
        width: 2500,
        height: 1994
    },
    {
        name: "SmallHD Cine 24 4K",
        category: "Monitors",
        slug: "smallhd-cine-24",
        cloudinaryImageId: "REIS/products/61dd407a1bc662149befaacb/1M2A1022_3",
        width: 2750,
        height: 2101
    },
    {
        name: "EcoFlow Delta Battery",
        category: "Power",
        slug: "ecoflow-delta-power-station",
        cloudinaryImageId: "REIS/products/61dd173c9b3c4a1c641a9825/0F9A5789_2",
        width: 2200,
        height: 2250
    },
    {
        name: "ARRI WCU-4",
        category: "FIZ Control",
        slug: "arri-wireless-compact-unit-wcu-4",
        cloudinaryImageId: "REIS/products/5fbc65d85e6b737f7ad82fc7/Arri_WCU4_remote_follow_focus_hand_controller",
        width: 600,
        height: 600
    },
    {
        name: "ARRI cforce mini",
        category: "FIZ Control",
        slug: "arri-cforce-mini-lens-motor",
        cloudinaryImageId: "REIS/products/5fbc641b5e6b730278d8297d/0P4A2268",
        width: 1962,
        height: 3033
    },
    {
        name: "OConnor 2560 Fluid Head",
        category: "Camera Support",
        slug: "oconnor-2560-fluid-head",
        cloudinaryImageId: "REIS/products/602dd7d0674c8c9a6d4bbc7f/oconnor_2560_fluid_head",
        width: 900,
        height: 900
    },
    {
        name: "Ronford Baker Slider",
        category: "Sliders",
        slug: "ronford-baker-bazooka-and-slider-kit",
        cloudinaryImageId: "REIS/products/5fb72a2835394aeaeee59922/ronford_baker_slider_on_bazooka_adjustable_riser_6",
        width: 1000,
        height: 1000
    },
    {
        name: "Teradek ACE 800",
        category: "Monitoring Accessories",
        slug: "teradek-ace-800-11-kit",
        cloudinaryImageId: "REIS/products/5fc43db9b4a62f2f4d727e4c/teradek_ace_800_kit",
        width: 2582,
        height: 3500
    },
    {
        name: "Atomos Sumo 19",
        category: "Monitors",
        slug: "atomos-sumo-19",
        cloudinaryImageId: "REIS/products/5fbd9cf25e6b7376d6d94289/atomos_sumo_19_inch_monitor_recorder",
        width: 1100,
        height: 768
    },
    {
        name: "Sony PVM-A170",
        category: "Monitors",
        slug: "sony-pvm-a170-17-oled-monitor",
        cloudinaryImageId: "REIS/products/5fbc92695e6b735a61d8cba7/sony_PVM_A170_17_inch_oled_production_monitor",
        width: 1000,
        height: 1000
    },
    {
        name: "Sachtler Video 18",
        category: "Camera Support",
        slug: "sachtler-video-18-s2-speedlock-tripod",
        cloudinaryImageId: "REIS/products/5fbc840c5e6b734a87d8855e/sachtler_video_18_s2_tripod",
        width: 1000,
        height: 1000
    },
    {
        name: "Rosco V-Hazer",
        category: "Haze Machines",
        slug: "rosco-v-hazer",
        cloudinaryImageId: "REIS/products/5fc42cc179f7697730b0f432/rosco_v_hazer_2",
        width: 1000,
        height: 1000
    },
    {
        name: "Block Battery D800",
        category: "Power",
        slug: "block-battery-d800",
        cloudinaryImageId: "REIS/products/5fcd7ca20b6fa6d2a83f7f9e/block_battery_800Wh",
        height: 950,
        width: 950
    },
    {
        name: "ARRI LMB-25 Matte Box",
        category: "Matte Boxes",
        slug: "arri-lmb-25-matte-box",
        cloudinaryImageId: "REIS/products/5fbc5e8c2a8ef05c5544e38f/arri_lmb_25_clip_on_matte_box",
        width: 1000,
        height: 1000
    },
];
